@font-face {
font-family: '__SFProDisplayFont_365e3f';
src: url(/_next/static/media/5d4798f5cfec42aa-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
}

@font-face {
font-family: '__SFProDisplayFont_365e3f';
src: url(/_next/static/media/a434d4288fd45765-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
}

@font-face {
font-family: '__SFProDisplayFont_365e3f';
src: url(/_next/static/media/83b456b980d483e3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
}

@font-face {
font-family: '__SFProDisplayFont_365e3f';
src: url(/_next/static/media/a38a57c02d80e59e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
}

@font-face {
font-family: '__SFProDisplayFont_365e3f';
src: url(/_next/static/media/cd68462f49e20ead-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: '__SFProDisplayFont_365e3f';
src: url(/_next/static/media/6870026b464e3f97-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: '__SFProDisplayFont_365e3f';
src: url(/_next/static/media/c3c0927e7e298e16-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
}

@font-face {
font-family: '__SFProDisplayFont_365e3f';
src: url(/_next/static/media/bb5c923536a18fa8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100;
}

@font-face {
font-family: '__SFProDisplayFont_365e3f';
src: url(/_next/static/media/0d04425f279cc1bb-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200;
}@font-face {font-family: '__SFProDisplayFont_Fallback_365e3f';src: local("Arial");ascent-override: 101.52%;descent-override: 25.72%;line-gap-override: 0.00%;size-adjust: 93.79%
}.__className_365e3f {font-family: '__SFProDisplayFont_365e3f', '__SFProDisplayFont_Fallback_365e3f'
}.__variable_365e3f {--default-font-name: '__SFProDisplayFont_365e3f', '__SFProDisplayFont_Fallback_365e3f'
}

